<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          :showBtn="true"
          :showSettingBtn="true"
          :showAddNew="false"
          title="Proceed Quotation"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
      />

      <div class="row mt-2">
        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Quotation Date:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input type="date"
                     class="form-control date-mask" v-model="cs_date" name="cs_date"
                     placeholder="CS Date">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Quotation Number:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="cs_number" name="cs_number"
                     placeholder="CS Number">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Requester:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="requester" name="requester"
                     placeholder="Requester">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Requisition No:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="requisition_no" name="requisition_no"
                     placeholder="Requisition No">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Department:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="department " name="department "
                     placeholder="Department">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Requisition Date:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input type="date"
                     class="form-control date-mask" v-model="requisition_date" name="requisition_date"
                     placeholder="Requisition Date">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Project:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="project" name="project"
                     placeholder="Project">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Project Budget Ref.</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="project_budget_ref" name="project_budget_ref"
                     placeholder="Project Budget Ref">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Business:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="business" name="business"
                     placeholder="Business">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Status:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <select class="form-control" v-model="status">
                <option :value="cs" selected>Quotation approval pending</option>
                <option :value="approval">Approval</option>
                <option :value="pending">Pending</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="px-2">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
        </li>
        <li class="nav-item">
          <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link" data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
        </li>
      </ul>
    </div>

    <div class="px-3">
      <Bill
          class="mt-2"
          v-for="(data, index) in accountsDetails"
          :key="index"
          :index="index"
          :data="data"
          :products="products"
          :accountHeads="accountHeads"
          :vatRate="vatRate"
          :isItem="isItem"
          @onClose="removeBill"
      />
      <Bill
          class="mt-2"
          v-for="(data, index) in itemsDetails"
          :key="index"
          :index="index"
          :data="data"
          :products="products"
          :accountHeads="accountHeads"
          :vatRate="vatRate"
          :isItem="isItem"
          @onClose="removeBill"
      />
    </div>

    <div class="px-2 mt-2">
      <button @click="addNewBill" class="btn btn-primary">Add line</button>
    </div>

    <div class="px-2 mt-3 mb-3">
      <div class="row justify-content-end">
        <div class="col-12 col-sm-3">
          <div class="row">
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-3">
                  <label class="col-form-label" for="first-name">Sub total</label>
                </div>
                <div class="col-sm-9">
                  <input
                      v-model="subTotal"
                      readonly
                      type="text"
                      class="form-control text-right"
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-3">
                  <label class="col-form-label" for="first-name">VAT</label>
                </div>
                <div class="col-sm-9">
                  <input
                      v-model="totalVAT"
                      readonly
                      type="text"
                      class="form-control text-right"
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-3">
                  <label class="col-form-label" for="first-name">Total</label>
                </div>
                <div class="col-sm-9">
                  <input
                      v-model="total"
                      readonly
                      type="text"
                      class="form-control text-right"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-12">
          <p class="h2 m-0">Attachment</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <div class="row d-flex flex-wrap justify-content-between align-items-center">
          <div class="col-4">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-12">
          <p class="h2 m-0">Memo</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <div class="mb-2"></div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-9">
          <p class="h2 m-0">Approval Matrix</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <div class="row d-flex flex-wrap justify-content-between align-items-center">
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">1</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">2</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">3</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">Final Approval</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="row mb-1 d-flex justify-content-end">
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-danger waves-effect waves-float waves-light form-control"
          >Reject
          </button>
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              class="btn btn-secondary waves-effect waves-float waves-light form-control"
          >Hold
          </button>
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

.title-background {
  background: #DBE7F2
}
</style>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import Pagination  from '@/components/atom/Pagination'
import Loader      from '@/components/atom/LoaderComponent'
import { ref, inject, reactive, onMounted, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleContact from '@/services/modules/contact'
import Bill from '@/components/molecule/procurement/vendor-selection/single-vendor/ProceedQuotationItem.vue'
import { json } from 'body-parser'

import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";

const router = useRouter()
const route = useRoute()

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchContactProfiles} = handleContact()

const companyId = computed(() => {
  return route.params.companyId
})
let loader =ref(false)
let productLoader =ref(false)
let saveButtonLoader=ref(false)
let saveNewButtonLoader =ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let accountPayable = ref([])
let prefix = ref('')
let serial = ref('')

let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  business_id: null,
  status: 'active',
  bill_number: '',
  date: '',
  has_item_detail: true,
  description: '',
  account_details: [],
  item_details: [
    {
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      description: ""
    }
  ],
})

onMounted(async () => {
  loader.value=true
  let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=bill_journal`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.date =  new Date().toISOString().split('T')[0]
  // const productQuery = `?company_id=${companyId.value}&product_type=finished_goods`
  const companyQuery = `?company_id=${companyId.value}`
  const productRes = fetchProductList(companyQuery)
  const accountHeadRes = fetchAccountHead(companyQuery)
  const businessRes = fetchBusinessList(companyQuery)
  const contactRes = fetchContactProfiles(companyQuery)
  const payableRes = rest.fetchAccountPayable(companyQuery)
  Promise.all([
    productRes.then(res=> {
      if(res.data) products.value = res.data
    }),
    accountHeadRes.then(res=> {
      if(res.data) accountHeads.value = res.data
    }),
    businessRes.then(res=> {
      if(res.data) business.value = res.data
    }),
    contactRes.then(res=> {
      if(res.data) contacts.value = res.data
    }),
    payableRes.then(res=> {
      if(res.data) {
        accountPayable.value = res.data
      }
      if(res.data?.length) {
        formData.value.account_head_id = res.data[0].id
      }
    })
  ])
      .then(() => {loader.value=false})
      .catch((err)=>{loader.value=false})
//   getProduct()
})

const hasItemDetails = computed(() => {
  return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
  return formData.value.account_details
})
const accountsDetails = computed(() => {
  return formData.value.item_details
})

const isItem = computed(() => {
  return formData.value.has_item_detail ? true : false
})
const selectionText = computed(() => {
  return formData.value.has_item_detail ? 'Item details' : 'Accounting Details'
})

const subTotal = computed(() => {
  let subTotal=0
  if(formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if(!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if(item.amount) {subTotal += parseInt(item.amount)}
    })
  }

  return subTotal
})

const totalVAT = computed(() => {
  let vat=0
  if(formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if(item.vat) {
        vat += parseInt((item.vat/100) * item.quantity * item.rate)
      }
    })
  }
  if(!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if(item.vat) {
        vat += parseInt((item.vat/100) * item.amount)
      }
    })
  }

  return vat.toFixed(2)
})

const total = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

watch(hasItemDetails, (newValue, oldValue) => {
  if(!newValue) {
    delete formData.value.item_details
    formData.value.account_details = [{
      account_head_id: null,
      amount: '',
      vat: 15,
      description: '',
      taxable_amount: null,
      tax_rate: null
    }]
  }
  if(newValue) {
    delete formData.value.account_details
    formData.value.item_details = [{
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      description: ""
    }]
  }
})

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickCloseButton() {
  router.push({name: 'quotation-list', params: route.params, query: route.query})
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function onClickSearchButton() {
  alert('Search Button clicked');
}

function onUploadLogo() {
  //
}

function onUploadCoverPhoto() {
  //
}

function getJournals() {
  //todo
}

function onPageChange() {
  //todo
}

function addNewBill() {
  if(hasItemDetails.value) {
    formData.value.item_details.push({
      product_id: null,
      quantity: '',
      rate: '',
      vat: 15,
      description: ''
    })
  }
  if(!hasItemDetails.value) {
    formData.value.account_details.push({
      account_head_id: null,
      amount: '',
      vat: 15,
      description: '',
      taxable_amount: null,
      tax_rate: null
    })
  }
}

function removeBill(index) {
  if(hasItemDetails.value) {
    formData.value.item_details.splice(index, 1)
  }
  if(!hasItemDetails.value) {
    formData.value.account_details.splice(index, 1)
  }
}
</script>
